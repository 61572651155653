<template>
  <div class="csp">
    <div class="csp-body">
      <div class="filter">
        <div class="option">
          <div class="filter-title"><span>{{ $i18n.t('csp.title1') }}</span></div>
          <div class="type-list">
            <div v-for="(item, index) in typeList" :key="index" class="item" :class="{'actived':avtiveMap.partnerType.activeItemIndex === index}" @click="selected('partnerType', item.value, index)"><span>{{ item.label }}</span></div>
          </div>
        </div>

        <div class="option">
          <div class="filter-title"><span>{{ $i18n.t('csp.title2') }}</span></div>
          <div class="type-list">
            <div v-for="(item, index) in languageList" :key="index" class="item" :class="{'actived': avtiveMap.language.activeItemIndex === index}" @click="selected('language', item, index)"><span>{{ item }}</span></div>
          </div>
        </div>

        <div class="search">
          <div class="count">{{ $i18n.t('csp.countLabel') }}<span>{{ count }}</span></div>
          <div class="search-bar">
            <input type="text" v-model="title" :placeholder="$i18n.t('csp.placeholder')"/>
            <div class="search-btn" @click="search"><i class="el-icon-search"></i></div>
          </div>
        </div>
      </div>

      <!-- <div class="tips-box">
        <p>To qualify for the HOLOWITS Certified Specialist exam, you should watch each course video for more than 80% of its duration. After the viewing duration of each video exceeds 80%, please contact <a>website@holowits.com</a> to request exam access.</p>
      </div> -->

      <div class="csp-list">
        <div class="csp-item" v-for="(item, index) in videoList" :key="index">
          <img @click="toPage(item.id)" :src="item.coverUrl" alt=""/>
          <div class="item-info">
            <p class="title">{{ item.title }}</p>
            <!-- <div class="descript">{{ item.describe }}</div> -->
            <div class="pro">
              <span>Learning Progress: {{ item.playSchedule | secondToMinute }} Min / {{ item.playTimes | secondToMinute }} Min</span> 
              <span>{{ (item.playSchedule / item.playTimes).toFixed(2) * 100}} %</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {checkCsp} from '@/api/userApi'
import {getList, getFilterList, search} from '@/api/cspVideoApi'
export default {
name: 'CspVideoFilter',
data() {
  return {
    typeList: [{label: 'All', value: '0'}],
    languageList: [],
    avtiveMap: {
      'partnerType': {
        activeItemIndex: 0,
      },
      'language': {
        activeItemIndex: 0,
      },
    },
    count: 0,
    title: '',
    selectedData: {
      language: 'English',
      filterId: '0'
    },
    videoList: []
  }
},
filters: {
  secondToMinute(second) {
    if (second) {
      return (second / 60).toFixed(2);
    }
    return second;
  }
},
async mounted() {
  await this.getFilterList();
  await this.getData();
},
methods: {
  async search() {
    const {data: {data, isSuccess}} = await search({
      title: this.title
    });
    if (isSuccess) {
      this.videoList = data;
    }
  },
  async getFilterList() {
    const {data: {data, isSuccess}} = await getFilterList();
    if (isSuccess) {
      for (let item of data) {
        if (item.type === 'Partner Type') {
          this.typeList.push({label: item.value, value: item.id});
        }
        if (item.type === 'Language') {
          this.languageList.push(item.value);
        }
      }
    }
  },
  selected(target, item, index) {
    this.avtiveMap[target].activeItemIndex = index;
    if (target === 'partnerType') {this.selectedData.filterId = item}
    if (target === 'language') {this.selectedData.language = item}
    this.getData()
  },
  tips() {
    const h = this.$createElement;
    this.$confirm('',{
      title: 'Tips',
      message: h('div', null, [
        h('p', null, '1. Please log in to your personal account on the top right corner of the web page: '),
        h('a', {attrs: { href: 'https://www.holowits.com/login' }, style: {'color': '#d2333c'}}, 'https://www.holowits.com/login'),
        h('p', null, [
          'If you do not have a personal account, apply for one at ',
          h('a', {attrs: { href: 'https://www.holowits.com/register' }, style: {'color': '#d2333c'}}, 'https://www.holowits.com/register')
        ]),
        h('p', {style: {'margin-top': '20px'}}, [
          '2. Please apply for a HOLOWITS Certified Specialist Exam account at ',
          h('a', {attrs: { href: 'https://www.holowits.com/learn-register' }, style: {'color': '#d2333c'}}, 'https://www.holowits.com/learn-register '),
          'if you did not registered before.'
        ]),
        h('p', {style: {'margin-top': '20px'}}, [
          'Any questions please contact ',
          h('a', {attrs: { href: 'mailto:website@holowits.com' }, style: {'color': '#d2333c'}}, 'website@holowits.com'),
        ]),
        
      ]),
      closeOnClickModal: false
    }).then(_ => {}).catch(_ => {});
  },
  // 根据过滤器获取视频列表
  async getData() {
    const {data: {data, isSuccess}} = await getList(this.selectedData)
    if (isSuccess) {
      this.videoList = data;
      this.count = data.length;
    }
  },
  async toPage(videoId) {
    // 判断是否登录了账号
    const account = this.$store.state.client.user.account;
    if (!account) {
      this.tips();
      return false;
    }
    // 验证 csp
    const hasAccess = await this.checkCsp(account);
    if (!hasAccess) {
      this.tips();
      return false;
    }
    // 将获取到的id拼装到url后跳转到对应的播放页面
    this.$router.push({
      path: `/csp-video/${videoId}`
    })
  },
  // 验证是否有注册了csp
  async checkCsp(account) {
    const {data: {isSuccess, data}} = await checkCsp({account: account})
    if (isSuccess) {
      // console.log(data)
      return data;
    }
  }
}
}
</script>

<style lang="less" scoped>
.csp {
  // width: 80%;
  min-height: inherit;
  background-color: #f0f0f0;
  padding-top: 20px;
  .csp-body {
    width: 70%;
    margin: auto;
  }
  .filter {
    width: 100%;
    padding: 0 15px;
    background-color: #fff;
    .option {
      display: flex;
      padding: 10px 0;
      border-bottom: 1px solid rgba(128, 128, 128, 0.192);
      &+.option {
        // margin-top: 12px;
      }
      .filter-title {
        flex-shrink: 0;
        padding: 4px 0px;
        font-weight: bold;
        width: 120px;
      }
      .type-list {
        margin-left: 30px;
        .item {
          display: inline-block;
          padding: 4px 6px;
          border: 1px solid transparent;
          cursor: pointer;
          // border-radius: 5px;
          &:hover {
            color: rgb(199, 0, 11);
            border: 1px solid rgb(199, 0, 11);
          }
          &+.item {
            margin-left: 20px;
          }
        }
        .actived {
          color: rgb(199, 0, 11);
          border: 1px solid rgb(199, 0, 11);
        }
      }
    }
    .search {
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .count {
        margin-right: 30px;
        span {
          color: rgba(199,0,11,0.76);
        }
      }
      .search-bar {
        display: flex;
        height: 30px;
        input {
          outline: 0;
          width: 204px;
          height: 30px;
          padding: 2px 10px;
          border: 1px solid #CCCCCC;
          border-radius: 2px 0 0 2px;
          border-right: none;
        }
        .search-btn {
          height: 30px;
          width: 30px;
          text-align: center;
          line-height: 30px;
          background-color: rgba(199,0,11,0.76);
          cursor: pointer;
          i {
            height: 30px;
            width: 30px;
            line-height: 30px;
            color: #ffffff;
            font-size: 18px;
          }
        }
      }
    }
  }
  .tips-box {
    margin-top:  20px;
    background-color: #ffffff;
    padding: 10px;
    a {
      color: rgb(199, 0, 11);
    }
  }
  .csp-list {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;

    .csp-item {
      background-color: #fff;
      width: calc(100% / 4 - 12px);
      display: inline-block;
      margin-bottom: 20px;
      &:not(:nth-child(4n)) {
        margin-right: 16px;
      }
      img {
        width: 100%;
        cursor: pointer;
      }
      .item-info {
        padding: 0 10px;
        padding-bottom: 10px;
        .title {
          padding: 5px 0;
          font-weight: bold;
          /* 设置文本不换行 */
          // white-space: nowrap;
          /* 设置多余文本以省略号的形式出现 */
          // text-overflow: ellipsis;
          // overflow: hidden;
        }
        .descript {
          // padding-bottom: 10px;
          font-size: 12px;
          color: #a7a7a7;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-all;
          height: 34.28px;
        }
        .pro{
          color: #a7a7a7;
          font-size: 12px;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
</style>